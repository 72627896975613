@tailwind base;

@tailwind components;

@tailwind utilities;

@font-face {
  font-family: "Argentun Medium";
  src: local("ArgentumSans-Medium"),
    url("./fonts/ArgentumSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Argentun Light";
  src: local("ArgentumSans-Light"),
    url("./fonts/ArgentumSans-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Argentun ExtraLight";
  src: local("ArgentumSans-ExtraLight"),
    url("./fonts/ArgentumSans-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Argentun Regular";
  src: local("ArgentumSans-Regukar"),
    url("./fonts/ArgentumSans-Regular.ttf") format("truetype");
}

p.caption.regular {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

p.caption.medium {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

button.regular {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.015em;
}

p.button.regular {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.015em;
}

button.medium {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.015em;
}

p.button.medium {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.015em;
}

button.bold {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.015em;
}

p.button.bold {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.015em;
}

p.body.regular {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.005em;
}

p.body.medium {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.005em;
}

p.body.bold {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

p.subtitle.regular {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

p.subtitle.medium {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;

  letter-spacing: 0.005em;
}

p.subtitle.bold {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.005em;
}

.textos.semibold {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
}

th.textos.semibold {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
}

.textos.regular {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
}

p.textos.medium {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
}

h1.bold {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
}

h1.medium {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
}

h2.bold {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
}

.drop_area {
  height: 48px;
}

h2.medium {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
}

h3.bold {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}

h3.medium {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}

h4.bold {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

h4.medium {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

input {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
}

select {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
}

option {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
}

.footer-bg {
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 116.67%
  );
  filter: drop-shadow(0px -1px 3px rgba(0, 0, 0, 0.25));
  backdrop-filter: blur(30px);
}

.comming-soon-bg {
  background-size: 50% auto;
  height: 100%;
  background-repeat: repeat-y;
}

.gradient-button {
  background: linear-gradient(91.82deg, #61008f 8.76%, #9747ff 100.34%);
}

.gradient-button:hover {
  background: #9747ff;
}

.background-faixa {
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.23) 116.67%
  );
  backdrop-filter: blur(60px);
  border-radius: 89px;
}

.form-background {
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 116.67%
  );
  backdrop-filter: blur(30px);
}

.header-background-mobile {
  background: rgba(164, 88, 199, 0.3);
  backdrop-filter: blur(7.5px);
}

.footer-background-web {
  filter: drop-shadow(0px -1px 3px rgba(0, 0, 0, 0.25));
  backdrop-filter: blur(30px);
}

.colab-bg-div {
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.25);
  background: #f2f2f2;
}

.bg-header-web-logger {
  background: linear-gradient(94.59deg, #61008f 23.82%, #9747ff 99.64%);
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(30px);
}

.gc-login-img {
  background: linear-gradient(94.59deg, #61008f 23.82%, #9747ff 99.64%);
}

div.white-box {
  background: #ffffff;
}

input[type="checkbox"] {
  -ms-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.4);
  padding: 10px;
  background: #ffffff;
  border-radius: 5px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 13px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  position: absolute;
  cursor: pointer;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ccc;
  transition: 0.4s;
  background: linear-gradient(180deg, #e7edf2 0%, #f5f7fa 100%);
  box-shadow: -4px -4px 6px rgba(255, 255, 255, 0.375623),
    4px 4px 6px rgba(171, 194, 212, 0.212126),
    inset 0px 4px 6px rgba(171, 194, 212, 0.5);
  border-radius: 8px;
}

.switch .slider::before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 0;
  bottom: -4px;
  background: rgb(235, 4, 4);
  transition: 0.4s;
  border-radius: 100%;
  background: #a458c7;
  box-shadow: -4px -4px 6px rgba(255, 255, 255, 0.5),
    4px 4px 6px rgba(171, 194, 212, 0.599836);
  border: 6px solid #f5f5f5;
}

input:checked + .slider::before {
  transform: translateX(26px);
  /* quando ativa o slider, da o efeito */
}

input:checked + .slider {
  background: #8400c2;
}

p.argentum-medium {
  font-family: "Argentun Medium";
  font-weight: 600;
  font-size: 24px;
  line-height: 28.8px;
}

.argentum-medium-18 {
  font-family: "Argentun Medium";
  font-weight: 500;
  font-size: 18px;
  line-height: 28.8px;
}

p.argentum-medium-15 {
  font-family: "Argentun Medium";
  font-weight: 500;
  font-size: 15px;
}

p.argentum-medium-16 {
  font-family: "Argentun Medium";
  font-weight: 500;
  font-size: 16px;
}

p.argentum-medium-14 {
  font-family: "Argentun Medium";
  font-weight: 500;
  font-size: 14px;
}

p.argentum-medium-12 {
  font-family: "Argentun Medium";
  font-weight: 500;
  font-size: 12px;
}

p.argentum-medium-500 {
  font-family: "Argentun Medium";
  font-weight: 500;
  font-size: 24px;
  line-height: 28.8px;
}

p.argentum-medium-30 {
  font-family: "Argentun Medium";
  font-weight: 600;
  font-size: 30px;
  line-height: 28.8px;
}

p.argentum-medium-28 {
  font-family: "Argentun Medium";
  font-weight: 600;
  font-size: 28px;
  line-height: 28.8px;
}

p.argentum-medium-20 {
  font-family: "Argentun Medium";
  font-weight: 400;
  font-size: 25px;
  line-height: 28.8px;
}

p.argentum-medium-36 {
  font-family: "Argentun Medium";
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
}

p.argentum-medium-56 {
  font-family: "Argentun Medium";
  font-weight: 600;
  font-size: 56px;
}

p.argentum-normal {
  font-family: "Argentun Medium";
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}

.argentum-normal {
  font-family: "Argentun Medium";
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
}

p.argentum-normal-25 {
  font-family: "Argentun Medium";
  font-weight: 500;
  font-size: 25px;
}

.argentum-normal-20 {
  font-family: "Argentun Medium";
  font-weight: 500;
  font-size: 20px;
}

.argentum-normal-16 {
  font-family: "Argentun Medium";
  font-weight: 500;
  font-size: 16px;
}

p.argentum-normal-10 {
  font-family: "Argentun Medium";
  font-weight: 500;
  font-size: 12px;
}

p.argentum-normal-600 {
  font-family: "Argentun Medium";
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
}

p.argentum-normal-300 {
  font-family: "Argentun Medium";
  font-weight: 100;
  font-size: 16px;
  line-height: 18.4px;
}

p.argentum-normal-400 {
  font-family: "Argentun Medium";
  font-weight: 600;
  font-size: 16px;
  line-height: 18.4px;
}

p.argentum-caption {
  font-family: "Argentun Light";
  font-weight: 300;
  font-size: 14px;
  line-height: 16.8px;
}

p.argentum-caption-18 {
  font-family: "Argentun Light";
  font-weight: 300;
  font-size: 18px;
  line-height: 20px;
}

p.argentum-caption-15 {
  font-family: "Argentun Light";
  font-weight: 300;
  font-size: 15px;
  line-height: 20px;
}

.argentum-caption-162 {
  font-family: "Argentun Light";
  font-weight: 300;
  font-size: 17px;
  line-height: 20px;
}

p.argentum-caption-24 {
  font-family: "Argentun Light";
  font-weight: 300;
  font-size: 24px;
  line-height: 28.8px;
}

p.argentum-caption-low {
  font-family: "Argentun Light";
  font-weight: 300;
  font-size: 14px;
  line-height: 16.8px;
}

p.argentum-caption-400 {
  font-family: "Argentun Light";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

p.argentum-caption-16 {
  font-family: "Argentun Light";
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
}

.argentum-extralight {
  font-family: "Argentun ExtraLight";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.argentum-extralight-8 {
  font-family: "Argentun ExtraLight";
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
}

p.argentum-extralight-14 {
  font-family: "Argentun ExtraLight";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

p.argentum-extralight-10 {
  font-family: "Argentun ExtraLight";
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
}

p.argentum-extralight-2 {
  font-family: "Argentun ExtraLight";
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
}

.argentum-extralight-3 {
  font-family: "Argentun ExtraLight";
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.argentum-extralight-4 {
  font-family: "Argentun ExtraLight";
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
}

.argentum-extralight-24 {
  font-family: "Argentun ExtraLight";
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
}

.argentum-extralight-25 {
  font-family: "Argentun ExtraLight";
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
}

#principal-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  transform: scale(1);
  background: linear-gradient(0deg, rgba(255, 4, 4, 0.2), rgba(27, 0, 0, 0.2));
}

video::-webkit-media-controls-start-playback-button {
  display: none;
}

#parceiros-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  transform: scale(1);
}

#empresas-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  transform: scale(1);
}

.cover-img {
  height: auto;
  object-fit: cover;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.cover-img-2 {
  height: 340px;
  width: 45%;

  object-fit: cover;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.arrow {
  border: solid #8400c2;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 12px;
}

input::-webkit-inner-spin-button {
  display: none;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.bg-decal {
  background: linear-gradient(0deg, rgb(0, 0, 0), rgba(0, 0, 0, 0));
}

.bg-decal-parceiros {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.647), rgba(0, 0, 0, 0));
}

.bg-decal-2 {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ),
    url("assets/bgForm.jpg");
  object-fit: cover;
  position: relative;
  background-position: center;
}

.bg-decal-22 {
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.6)
    ),
    url("assets/formEmpresa.jpg");
  object-fit: cover;
  position: relative;
  background-position: center;
}

.button-filtros {
  background: #f8f8f8;
  box-shadow: 4px 4px 6px rgba(255, 255, 255, 0.5),
    -2px -1px 5px rgba(171, 194, 212, 0.599836);
  border-radius: 10px;
}

.dropdown {
  box-shadow: 4px 4px 6px rgba(255, 255, 255, 0.5),
    -2px -1px 5px rgba(171, 194, 212, 0.599836);
  border-radius: 10px;
}

.button-filtros-selected {
  background: #c445ff;
  border-radius: 10px;
}

.bg-parceiro-2 {
  background-size: contain;
  background-position: center;
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 44.26%,
      rgba(0, 0, 0, 0.5) 100%
    ),
    url("assets/fundoParceiros.jpg");
}

.shadowLP {
  filter: drop-shadow(0px 2.53803px 20.3043px rgba(0, 0, 0, 0.05));
}

.div-lp {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ffffff;
  backdrop-filter: blur(5px);

  border-radius: 5px;
}

#send-lp-form {
  background: linear-gradient(94.59deg, #61008f 23.82%, #9747ff 99.64%);
}

#send-parceiro {
  background: linear-gradient(94.59deg, #61008f 23.82%, #9747ff 99.64%);
}

.bg-decal-3 {
  background-position: center center;
  background-repeat: no-repeat;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%),
    url("assets/backgroundPagar.svg");
}

.bg-decal-4 {
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(
      90deg,
      #260137 30.63%,
      rgba(0, 0, 0, 0) 65.02%
    ),
    url("assets/backgroundPagar.svg");
}

.bg-parceiros-lp {
  background: linear-gradient(
    147.84deg,
    rgba(0, 0, 0, 0.19) -28.69%,
    #000000 117.98%
  );
}

.divisao-lp-line {
  opacity: 0.5;
  border: 1px solid #c5d5e2;
  box-shadow: 0px 1px 1px #ffffff;
}

.border-fav {
  border: 2px solid;
}

.fade-vagas {
  background: linear-gradient(
    147.84deg,
    rgba(0, 0, 0, 0.19) -28.69%,
    #000000 117.98%
  );
}
