.countdown-timer {
  width: 100%;
  height: 100%;

  font-family: "Oswald";
  font-size: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 45px;
}

.upcomming-num {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 61px;
  letter-spacing: -0.005em;
  color: #a458c7;
}

.upcomming-legend {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.005em;
  color: #a458c7;
}

.columns-upcomming-div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media screen and (max-width: 1260px) {
  .upcomming-num {
    font-size: 3rem;
  }

  .countdown-timer {
    gap: 12px;
    align-items: baseline;
  }
}
